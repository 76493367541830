import React from 'react';
import MuiPhoneNumber from 'mui-phone-number';
import { useField, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  phoneInput: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px',
      height: '40px',
      '& fieldset': {
        borderColor: theme.palette.action.disabled,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.text.secondary,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiInputBase-input': {
      paddingTop: '12px',
      color: (props) =>
        props.themeMode === 'light'
          ? theme.palette.light.main
          : theme.palette.dark.main,
    },
    '& .MuiInputLabel-outlined': {
      color: theme.palette.primary.main,
      '&.Mui-focused': {
        color: theme.palette.primary.main,
      },
    },
  },
}));

const PhoneInput = ({ name, label, ...other }) => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleOnChange = (phone) => {
    setFieldValue(name, phone);
  };

  return (
    <MuiPhoneNumber
      defaultCountry="br"
      name={name}
      label={label}
      value={field.value}
      onChange={handleOnChange}
      onBlur={field.onBlur}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
      variant="outlined"
      fullWidth
      size="small"
      className={classes.phoneInput}
      disableDropdown={false}
      InputLabelProps={{
        shrink: true,
      }}
      {...other}
    />
  );
};

PhoneInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default PhoneInput;
